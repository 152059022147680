import React from "react";
import PublicRoutes from "./routes/PublicRoutes";

const App = () => {
	return (
		<>
			<PublicRoutes />
		</>
	);
};

export default App;
